import {CompetitionRef} from "@/modules/competition/service/CompetitionService";
import {RefWithName} from "@/utils/Utils";
import {httpService} from "@/services/HttpService";
import {
    CompetitionTestRef,
    CompetitionTestSetRef,
    SportCategory
} from "@/modules/competition/service/CompetitionTestsService";
import {SportTypeVariant} from "@/modules/federation/services/SportTypeVariantService";
import {
    PractitionerResult,
    ResultDataHeader,
    StartlistEntryRef
} from "@/modules/competition/service/ManageResultsService";
import {CompetitionPractitioner} from "@/shared/domain/CompetitionPractitioner";
import {SportResultStatus} from "@/components/competition/SportResult";

class ManageRankingService {
    getData(ref: CompetitionRef): Promise<CompetitionRankingData> {
        return httpService.get(`/competition/manage/${ref}/ranking/metadata`)
    }

    getRanking(competition: CompetitionRef, test: CompetitionTestRef, testSetRef: CompetitionTestSetRef, rankingRef: string): Promise<CompetitionRanking> {
        return httpService.get(`/competition/manage/${competition}/ranking/${test}/${testSetRef}/${rankingRef}`)
    }

    recalculate(competition: CompetitionRef, test: CompetitionTestRef, testSetRef: CompetitionTestSetRef, rankingRef: string): Promise<void> {
        return httpService.get(`/competition/manage/${competition}/ranking/${test}/${testSetRef}/${rankingRef}/recalculate`)
    }

    calculatePoints(competition: CompetitionRef, overwrite: boolean, assign: boolean, includeCareer: boolean, includeChampionship: boolean): Promise<void> {
        return httpService.post(`/competition/manage/${competition}/ranking/points/calculate`, {
            overwrite: overwrite,
            assign: assign,
            includeCareer: includeCareer,
            includeChampionship: includeChampionship
        })
    }

    assignPoints(competition: CompetitionRef): Promise<void> {
        return httpService.post(`/competition/manage/${competition}/ranking/points/assign`, {})
    }

    updatePublishOptions(competition: CompetitionRef, rankingRef: string, options: RankingPublishOptions): Promise<RankingPublishOptions> {
        return httpService.post(`/competition/manage/${competition}/ranking/${rankingRef}/publish`, options)
    }

    closeCompetitionTestSet(ref: CompetitionRef, testRef: string, testSetRef: string): Promise<PractitionerResult> {
        return httpService.post(`/competition/manage/${ref}/ranking/${encodeURIComponent(testRef)}/${encodeURIComponent(testSetRef)}/close`, {})
    }

    reopenCompetitionTestSet(ref: CompetitionRef, testRef: string, testSetRef: string): Promise<PractitionerResult> {
        return httpService.post(`/competition/manage/${ref}/ranking/${encodeURIComponent(testRef)}/${encodeURIComponent(testSetRef)}/reopen`, {})
    }
}

export interface CompetitionRankingData {
    menu: RankingMenu
}

export interface RankingMenu {
    entries: Array<SportTypeVariantMenuEntry>
}

export interface SportTypeVariantMenuEntry {
    ref: string
    name: string
    entries: Array<CompetitionTestMenuEntry>
}

export interface CompetitionTestMenuEntry {
    ref: string
    name: string
    test: RefWithName
    testSet: RefWithName
    hasMultipleTestSets: boolean,
    hasMultipleRankings: boolean,
    includedInCareer: boolean,
    includedInChampionship: boolean,
    rankings: Array<RankingTestMenuEntry>
    isFinal: boolean
}

export interface RankingTestMenuEntry {
    ref: string;
    name: string;
    publication: boolean;
    isFinal: boolean;
}

export interface CompetitionRanking {
    competitionRef: CompetitionTestRef,
    test: RefWithName
    testSet: RefWithName
    rankingRef: string,
    name: string,
    sportTypeVariant: SportTypeVariant,
    sportCategory?: SportCategory,
    career?: RefWithName,
    championship?: RefWithName,
    protocol: RefWithName,
    headers: Array<ResultDataHeader>,
    careerHeaders: Array<PointsHeader>,
    championshipHeaders: Array<PointsHeader>,
    entries: Array<PractitionerRankingResource>,
    careerPoints: Array<Point>,
    championshipPoints: Array<Point>,
    publishOptions: RankingPublishOptions,
    isFinal: boolean
}

export interface RankingPublishOptions {
    includeParticipants: boolean,
    includeFields: boolean,
    excludedFields: Array<number>
}

export interface PointsHeader {
    ref: string,
    name: string,
    order: number
}

export interface Point {
    canEarnPoints: boolean,
    hasPoints: boolean,
    practitionerRef?: string,
    points?: number,
    assignedManually?: boolean,
    assigned: boolean,
    comments?: string,
}

export enum PractitionerCareerPointSource {
    CALCULATED = "CALCULATED",
    MANUAL = "MANUAL"
}

export interface PractitionerRankingResource {
    ref: StartlistEntryRef,
    order: number,
    outOfCompetition: boolean,
    participationOrder: number,
    practitioner: CompetitionPractitioner,
    results: Array<RankingField>
    rank: Rank,
}

export enum RankingFieldType {
    POINTS = "POINTS",
    PERCENTAGE = "PERCENTAGE",
    MONEY = "MONEY",
    SHOW_JUMPING = "SHOW_JUMPING",
    DRESSAGE = "DRESSAGE",
    CROSS_COUNTRY = "CROSS_COUNTRY",
    EVENTING = "EVENTING",
}

export interface RankingField {
    type: RankingFieldType;
    order: number;
    visible: boolean;
}

export interface WithRank {
    rank?: number
}

export interface PointsField extends RankingField {
    points?: number
}

export interface PercentageField extends RankingField {
    percentage?: number
}

export interface MoneyField extends RankingField {
    value?: number
    currency?: string
}

export interface JumpingField extends RankingField, WithRank {
    status: SportResultStatus;
    time?: number;
    obstacleErrorTotal?: number;
    timeErrorTotal?: number;
    totalErrors?: number;
}

export interface DressageField extends RankingField, WithRank {
    status: SportResultStatus;
    totalPoints?: number;
    totalPercentage?: number;
}

export interface CrossCountryField extends RankingField, WithRank {
    status: SportResultStatus;
    time?: number;
    obstacleErrorTotal?: number;
    timeErrorTotal?: number;
    totalErrors?: number;
}

export interface EventingField extends RankingField {
    status: SportResultStatus;
    penalties?: number;
    penaltiesCrossCountry?: number;
    statusCrossCountry?: SportResultStatus;
    penaltiesDressage?: number;
    statusDressage?: SportResultStatus;
    penaltiesJumping?: number;
    statusJumping?: SportResultStatus;
}

export interface Rank {
    value?: number,
    hasRank: boolean
}

export const manageRankingService = new ManageRankingService();
