
import {Component, Prop, Vue, Watch} from 'vue-property-decorator';
import {Account, ContactType} from "@/modules/federation/services/InvoiceService";
import AddressComponent from "@/components/location/AddressComponent.vue";
import EqifyDataLoader from "@/components/EqifyDataLoader.vue";
import {Loader} from "@/utils/Loader";

@Component({
  components: {EqifyDataLoader, AddressComponent}
})
export default class AccountSelector extends Vue {
  @Prop({required: true, type: Function}) resolver!: () => Promise<Array<ContactWithAccounts>>
  @Prop({
    required: false,
    type: Array,
    default: () => [ContactType.PERSON, ContactType.ORGANISATION]
  }) contactTypes!: ContactType[]

  @Prop() value!: SelectedAccount

  private loader = Loader.create()

  contacts: Array<ContactWithAccounts> = []

  mounted() {
    this.contacts = []
    this.loader.load(async () => {
      await this.resolver()
          .then((r) => {
            this.contacts = r
            if (!this.value.ref) {
              this.onContactTypeChange(this.firstContactType)
            }
          })
    })
  }

  private onContactTypeChange(type: ContactType | undefined) {
    if (type) {
      this.loader.load(async () => {
        this.value.contactType = type
        const contacts = this.contactsByType
        if (contacts.length > 0) {
          this.value.contactRef = contacts[0].contactRef
          this.value.contactName = contacts[0].contactName
        }

        const accounts = this.accountsForContactType
        if (accounts.length > 0) {
          this.accountRef = accounts[0].ref
        }

        this.$emit("input", this.value)
      })
    }
  }

  get contactType() {
    return this.value?.contactType
  }

  get firstContactType() {
    const contacts = this.contactTypesThatHaveContacts
    if (this.contacts.length > 0) {
      return contacts[0].value
    } else return undefined
  }

  get contactRef() {
    return this.value.ref
  }

  set contactRef(ref: string | undefined) {
    this.value.ref = ref
    this.$emit("input", this.value)
  }

  get accountRef() {
    return this.value.ref
  }

  set accountRef(ref: string | undefined) {
    this.value.ref = ref
    this.$emit("input", this.value)
  }

  get contactTypesThatHaveContacts() {
    return this.contactTypes.filter(ct => this.contacts.filter(it => it.contactType == ct).length > 0).map(it => {
      return {
        value: it,
        text: this.$t(`members.profile.table.label.billto_${it}`)
      }
    })
  }

  get contactsByType() {
    if (this.value && this.value.contactType) {

      return this.contacts.filter(it => it.contactType == this.value.contactType)
    } else {
      return []
    }
  }

  get accountsForContactType() {
    if (this.value && this.value.contactType) {
      const c = this.contactsByType.find(it => it.contactRef == this.value.contactRef)
      if (c) {
        return c.accounts
      }
    }
    return []
  }

  get hasMultipleContacts() {
    return this.contactsByType.length > 1
  }
}

export interface ContactWithAccounts {
  contactRef: string,
  contactName: string,
  contactType: ContactType,
  accounts: Array<Account>
}

export interface SelectedAccount {
  ref?: string,
  contactRef?: string,
  contactName?: string,
  contactType?: ContactType
  name?: string
}


