import {UserProfile, UserProfileData} from "@/shared/domain/User";
import axios from "axios";

export async function getUserProfile(): Promise<UserProfile | undefined> {
    const r = await axios.get("/api/auth/user")
    if (r.status == 200) {
        return Promise.resolve(new UserProfile(r.data as UserProfileData))
    } else {
        return Promise.resolve(undefined)
    }
}

export async function logout(): Promise<void> {
    const r = await axios.delete("/api/auth/user")
    if (r.status == 200) {
        return Promise.resolve()
    } else {
        return Promise.resolve()
    }
}

