
import {Prop, Vue} from "vue-property-decorator";
import Component from "vue-class-component";
import EqifyRuleComponent from "@/components/rules/components/EqifyRuleComponent.vue";
import {initLicenseData, LicenseData} from "@/modules/members/services/MemberLicenseService";
import LicenseSelectComponent from "@/components/rules/components/LicenseSelectComponent.vue"

@Component({
  components: {LicenseSelectComponent, EqifyRuleComponent}
})
export default class EqifyRulePersonHasLicense extends Vue {
  @Prop() value?: PersonHasLicense

  get license(): LicenseData {
    return this.value?.license || initLicenseData()
  }

  set license(val: LicenseData) {
    this.$emit("input", {
      license: val,
      required: this.required,
    })
  }

  get required(): boolean {
    if (this.value == undefined) {
      return true
    } else {
      return this.value.required
    }
  }

  set required(val: boolean) {
    this.$emit("input", {
      license: this.license,
      required: val,
    })
  }

  get inverted(): boolean {
    if (this.value == undefined) {
      return false
    } else {
      return this.value.inverted
    }
  }

  set inverted(val: boolean) {
    this.$emit("input", {
      license: this.license,
      required: this.required,
      inverted: val
    })
  }

}

export interface PersonHasLicense {
  license: LicenseData,
  required: boolean,
  inverted: boolean
}
