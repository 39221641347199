
import {Prop, Vue} from "vue-property-decorator";
import Component from "vue-class-component";
import EqifyRuleComponent from "@/components/rules/components/EqifyRuleComponent.vue";

@Component({
  components: {EqifyRuleComponent}
})
export default class extends Vue {
  @Prop() value?: HorseHasAge

  get age(): number {
    return this.value?.age || 0
  }

  set age(val: number) {
    this.$emit("input", {
      age: val,
      optional: this.required
    })
  }

  get required(): boolean {
    if (this.value == undefined) {
      return true
    } else {
      return this.value.required
    }
  }

  set required(val: boolean) {
    this.$emit("input", {
      age: this.age,
      required: val
    })
  }
}

export interface HorseHasAge {
  age: number,
  required: boolean
}


