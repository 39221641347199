
import EqifyRuleComponent from "@/components/rules/components/EqifyRuleComponent.vue";
import {Component, Prop, Vue} from "vue-property-decorator";
import {RefWithName} from "@/utils/Utils";
import StudBookSearchInput from "@/components/general/StudBookSearchInput.vue";

@Component({
  components: {StudBookSearchInput, EqifyRuleComponent}
})
export default class EqifyRuleHorseRegisteredToStudbook extends Vue {
  @Prop() value?: HorseRegisteredToStudbook

  get studbook(): RefWithName | undefined {
    return this.value?.studbook || undefined
  }

  set studbook(val: RefWithName | undefined) {
    this.$emit("input", {
      studbook: val,
      required: this.required,
      inverted: this.inverted
    })
  }

  get required(): boolean {
    if (this.value == undefined) {
      return true
    } else {
      return this.value.required
    }
  }

  set required(val: boolean) {
    this.$emit("input", {
      studbook: this.studbook,
      required: val
    })
  }

  get inverted(): boolean {
    if (this.value == undefined) {
      return false
    } else {
      return this.value.inverted
    }
  }

  set inverted(val: boolean) {
    this.$emit("input", {
      studbook: this.studbook,
      required: this.required,
      inverted: val
    })
  }
}

export interface HorseRegisteredToStudbook {
  studbook: RefWithName,
  required: boolean,
  inverted: boolean
}

