
import {Prop, Vue} from "vue-property-decorator";
import EqifyRuleComponent from "@/components/rules/components/EqifyRuleComponent.vue";
import Component from "vue-class-component";

@Component({
  components: {EqifyRuleComponent}
})
export default class extends Vue {
  @Prop() value?: PersonHasIndividualCombinations

  get individualCombinations(): number {
    return this.value?.count || 0
  }

  set individualCombinations(val: number) {
    this.$emit("input", {
      count: val,
      optional: this.required
    })
  }

  get required(): boolean {
    if (this.value == undefined) {
      return true
    } else {
      return this.value.required
    }
  }

  set required(val: boolean) {
    this.$emit("input", {
      count: this.individualCombinations,
      required: val,
    })
  }

}

export interface PersonHasIndividualCombinations {
  count: number,
  required: boolean,
}

