import { render, staticRenderFns } from "./EqifyRulePersonHasIndividualCombinations.vue?vue&type=template&id=ae2d0cf8&scoped=true"
import script from "./EqifyRulePersonHasIndividualCombinations.vue?vue&type=script&lang=ts"
export * from "./EqifyRulePersonHasIndividualCombinations.vue?vue&type=script&lang=ts"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "ae2d0cf8",
  null
  
)

export default component.exports